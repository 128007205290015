$grey-1: #686868;
$grey-2: #bcbcbc;
$grey-3: #ececec;
$grey-4: #d9d9d9;
$violet-secundary: #41479b;
$blue-secondary: #0e9ce2;
$blue-dark: #357b9eff;
$blue-mid: #79a6cf;
$blue-principal: #4987be;
$blue-light: #a5c4e0;
$orange-error: #ff852c;
$grey-secondary: #4f4f4f;
$red-light: #feebebff;
$red-principal: #ff7676ff;
$green-light: #c2f2deff;
$green-principal: #67e0aeff;
