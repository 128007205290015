@import '../../variables.scss';

.communication-container {
  border: 1px solid $blue-principal;
  background-color: white;
  border-radius: 8px;
  color: rgba(75, 75, 75, 0.75);
}

.communication-delivered-background {
  background-color: $green-light;
  border-color: $green-principal;
}

.communication-canceled-background {
  background-color: $grey-2;
  border-color: $grey-1;
}

.note-updated {
  color: rgba(75, 75, 75, 0.75);
  font-style: italic;
}

.communication-error-background {
  background-color: $red-light;
  border-color: $red-principal;
}
